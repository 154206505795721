  .ant-layout.ant-layout-has-sider{min-height: 100vh;}
  /* .ant-layout{margin-left: 200px;} */
  .test{background-color:#fff;}
  .homePage{height: 100vh;}
  .app { min-height: 100vh;}
  .app .pointer { cursor: pointer;}
  .app a { cursor: pointer;display: inline-block;white-space: nowrap;}
  .app hr { height: 1px; border: none; background-color: #e8e8e8;}
  .app .middle { display: flex; justify-content: center; align-items: center;}
  .app .ph100 { height: 100%;}
  .app .colorRed { color: red;}
  .app .form { padding: 0px;}
  .app .form .ant-form-item { display: flex; margin-bottom: 16px;}
  .app .form .ant-form-item-control-wrapper { flex: 1;}
  .app .pagination-right { display: flex; margin: 16px 0px; justify-content: flex-end;}
  .app .ant-pagination-options-size-changer.ant-select { margin-right: 0px;}
  .ant-select{min-width: 20%;}
  .top {
    background-color: #ddf;
    color: #333;
    border: 1px solid silver;
    box-shadow: 3px 4px 3px 0px silver;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 5px;
    overflow: hidden;
    line-height: 20px;
}
  .top input[type="text"] {
    height: 25px;
    border: 0;
    padding-left: 5px;
    width: 280px;
    border-radius: 3px;
    outline: none;
}
  


  /* 底部+头部+侧边栏 */
  .footer {text-align: center;padding: 24px 0px;}
  .ant-layout-header {height: 64px; padding: 0 !important; color: rgba(0, 0, 0, 0.65);line-height: 64px;background-color: rgba(255,255,255,1) !important;}
  .header {margin-left:200px;padding: 0;box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);position: fixed;display: flex;justify-content: space-between;height: 64px;z-index: 9;align-items: center;background-color: #fff;width:100%;padding-right: 200px !important;}
  .header .rightcon {display: flex;}
  .header .trigger {font-size: 18px;line-height: 64px;padding: 0 24px;cursor: pointer; transition: color 0.3s;}
  .header .trigger:hover {color: #1890ff;}
  .header.fixed {position: fixed;top: 0;right: 0;width: calc(100% - 256px); z-index: 29; transition: width 0.2s;}
  .header.fixed.collapsed {width: calc(100% - 80px);}
  .header .ant-menu-submenu-title {height: 64px;}
  .header .ant-menu-horizontal {line-height: 64px;}
  .header .ant-menu-horizontal > .ant-menu-submenu:hover {background-color: #e6e6e6;}
  .header .ant-menu {border-bottom: none;height: 64px;}
  .header .ant-menu-horizontal > .ant-menu-submenu {top: 0;margin-top: 0;}
  .header .ant-menu-horizontal > .ant-menu-item,
  .header .ant-menu-horizontal > .ant-menu-submenu {border-bottom: none;}
  .header .ant-menu-horizontal > .ant-menu-item-active,
  .header .ant-menu-horizontal > .ant-menu-item-open,
  .header .ant-menu-horizontal > .ant-menu-item-selected,
  .header .ant-menu-horizontal > .ant-menu-item:hover,
  .header .ant-menu-horizontal > .ant-menu-submenu-active,
  .header .ant-menu-horizontal > .ant-menu-submenu-open,
  .header .ant-menu-horizontal > .ant-menu-submenu-selected,
  .header .ant-menu-horizontal > .ant-menu-submenu:hover {border-bottom: none;}
  .header .rightContainer {display: flex;align-items: center;}
  .header .button {width: 64px;height: 64px;line-height: 64px;text-align: center;font-size: 18px;cursor: pointer;}
  .iconButton {width: 48px;height: 48px;display: flex;justify-content: center;align-items: center;border-radius: 24px;cursor: pointer;}
  .iconButton + .iconButton {margin-left: 8px;}
  .iconButton .iconFont {color: #b2b0c7;font-size: 24px;}
  @media (max-width: 767px) {.header {width: 100% !important;}}
  .sider{display: flex;align-items: center;justify-content: center;position: fixed !important ;height: 100vh !important;overflow-y: auto;}
  .sider .logo {height: 64px;line-height: 64px;padding-left: 24px;font-size: 18px;font-weight: 500;color: #fff;overflow: hidden; word-break: keep-all;white-space: nowrap;text-overflow: ellipsis;}
  .sider .logo img {margin-right: 8px;width: 32px;height: 32px;border-radius: 16px;}
  .sider .hide {display: none;}
 

  /* orderinfo */
  .ant-layout-content{margin-left: 200px;margin-top: 74px;}
  .content .header {padding: 12px 24px;background-color: #fff;}
  .content .header .title {margin-top: 8px;font-size: 20px;font-weight: 600;color: rgba(0, 0, 0, 0.85);}
  .content .body {/* display: flex; */padding: 24px;}
  .content .body .card {width: calc(-14.666666666666664%);margin-right: 24px;padding: 48px 16px;text-align: center;font-size: 20px;font-weight: 600;color: rgba(0, 0, 0, 0.85);background-color: #fff;border: 1px solid #e8e8e8;}
  .content .body .card:hover {position: relative;z-index: 1;box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);}
  .orderDetails,.orderDetails p,.orderDetails div,.orderDetails span,.orderDetails a{margin:0;padding:0;font-size:14px;}
  .orderDetails{padding:24px;background:#fff;}
  .orderDetails .status{margin-bottom:20px;background:#fff5f5;border:1px solid #ff4d4f;padding:16px 16px 20px 16px;}
  .orderDetails .link{display: inline-block;padding:0 6px;margin:0 6px;line-height:1.4;color:#ff4d4f;padding: 4px 8px;}
  .orderDetails .details{border:1px solid #ebebeb;padding:10px 20px;margin-top:46px;position: relative;}
  .orderDetails .details .orderTitle{margin-top:-24px;border:1px solid #ebebeb;border-bottom:none;position: absolute;top:-7px;left:20px;line-height:22px;padding:0 14px;line-height:30px;background:#fff;}
  .orderDetails .orderMode{padding:12px 0;border-bottom:1px solid #ebebeb;}
  .orderDetails .orderModeWuliu{border-bottom: none;margin-top: 15px;}
  .orderDetails .orderMode .mt{padding-bottom:6px;}
  .orderDetails .orderMode .shopItem .name{color:#ff4d4f;}
  .orderDetails .shopItem{padding-bottom:10px;}
  .orderDetails .shopItem .item{display: inline-block;margin-right:20px;}
  .orderDetails .orderInfo{width:100%;}
  .orderDetails .orderInfo td{width:33.33%;padding:4px 0;}
  .orderDetails .goodsInfo{width:100%;border-collapse:separate;border:1px solid #f2f2f2;padding:0;margin:0;font-size:12px;}
  .orderDetails .goodsInfo .th{background:#f2f2f2;height:26px;}
  .orderDetails .goodsInfo .th th{font-weight: normal;}
  .orderDetails .goodsInfo .td td{padding:12px 0;}
  .orderDetails .goodsInfo .img img{width:60px;height:60px;}
  .orderDetails .goodsInfo .good {padding: 0 10px;align-items: center;display: flex;}
  .orderDetails .goodsInfo .good .img{float: left;width: 60px;}
  .orderDetails .goodsInfo .good .name{line-height:1.3;height:2.6em;overflow: hidden;}
  .orderDetails .goodsInfo .good .tag{width: 100px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;;display: inline-block;border:1px solid #ff4d4f;line-height:1.4;padding:0 6px;color:#ff4d4f;text-align: center;}
  .orderDetails .goodsInfo .good .info{padding-left:10px;}
  .orderDetails .goodsInfo .btn{color:#ff4d4f;display: inline-block;margin:0 4px;}
  .orderDetails .list{list-style:none;padding:0;margin:4px 0;}
  .orderDetails .listBt{border-bottom:1px solid #ebebeb;padding-bottom:10px;margin-bottom:10px;}
  .orderDetails .list li{line-height: 1.4;margin:4px 0;}
  .orderDetails .th1 { width: 30%; text-align: center; }
  .orderDetails .th2 { width: 10%; text-align: center; }

  /* uploadimage */
  /* 配合样式可以做出上传按钮和示例效果 */
  .ant-upload.ant-upload-select-picture-card > .ant-upload{display:flex !important;flex-direction: column;justify-content: center;}
  .ant-upload-select-picture-card i {font-size: 28px;color: #999;}
  .ant-upload-select-picture-card .ant-upload-text { font-size: 12px;color: #666;}
  .upload-example {position: relative;display: inline-block;height: 96px; width: 96px;padding: 8px;border: 1px solid #d9d9d9;border-radius: 6px;vertical-align: top;}
  .upload-example img {height: 78px;width: 78px;}
  .upload-example:before {position: absolute;bottom: 8px;left: 8px;content: ' ';width: 78px;height: 24px;background-color: #808080;opacity: .8;}
  .upload-example span {position: absolute;bottom: 8px;left: 8px;width: 78px;height: 24px;color: #fff;line-height: 24px;text-align: center;}
  .img{width: 100%;margin-top: 30px;}

  /* loginform */
  #login-page{position: fixed;display: flex;justify-content: center;align-items: center;top: 0;left: 0;width: 100%;height: 100%;background:#DEF3F4;z-index: 99;}
  /* #login-page .container{position: relative;width: 240px;height: 300px;padding: 100px 40px 40px 40px;box-sizing: content-box;background-color:rgba(255, 255, 255, 0.97);box-shadow: rgb(0, 0, 0) 0px 0px 10px;} */
  #login-page .container{background-color: rgba(255, 255, 255, 0.97);padding: 50px 60px 20px;border-radius: 4px;position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);box-shadow: rgb(0, 0, 0) 0px 0px 10px;}
  #login-page .box{position: absolute;top: 0;left: 0;width: 100%;height: 100%;padding: 90px 40px 40px 40px;backface-visibility: hidden;background: linear-gradient(230deg, rgba(53, 57, 74, 0) 0%, rgb(0, 0, 0) 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(53, 57, 74, 0)', endColorstr='rgb(0, 0, 0)',GradientType=1 );box-shadow: -15px 15px 15px rgba(0,0,0,.4);transition: all 1s;}
  #login-page .showBox{transform:rotateY(0deg);}
  #login-page .hiddenBox{transform:rotateY(180deg);}
  #login-page .loginBtn{border: 1px solid #ff0000;border-radius: 2px;font-size: 11px;color: white;transition: all .2s;}
  #login-page .bottom{display: flex;height: 42px;justify-content: space-between;align-items: center;}
  #login-page .registerBtn{color: #D3D7F7;}
  #login-page .registerBtn:hover{color: #ff0000;cursor: pointer;}
  #login-page .loginBtn:hover{color: white;background: #ff0000;cursor: pointer;}
  #login-page .title{height: 60px;color:#D3D7F7;font-size: 16px;margin-bottom: 0;}
  /* #login-page .footer{position: absolute;bottom: 0px;left: 35px;width: 250px;color: #D3D7F7;font-size: 10px;} */
  #login-page .footer{margin-top: 10px;    font-size: 16px;    color: #ccc;    text-align: center;}
  /*覆盖antd的默认样式*/
  #login-page input{color:#333;outline: none;box-shadow: none;background: transparent;}
  #login-page .ant-input-group-addon{background: transparent;padding:0;border: none;color: #fff;opacity: 0.8;}
  #login-page .ant-input-group-addon .iconfont{display: inline-block;width: 30px;transition: all .3s;opacity: 0.6;}
  #login-page .ant-form{margin-top: 30px;}
  #login-page .ant-form-item{}
  #login-page .ant-form-explain{position: absolute;z-index: 99;left:110%;top:0;height: 41px;/*box-shadow: -2px 2px 2px rgba(0,0,0,.3);*/}
  .login-notification .ant-notification-notice-message{color:#D3D7F7;}
  .login-notification .ant-notification-notice-close{color:#D3D7F7;}
  /*更改谷歌浏览器input背景*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {/*延迟背景颜色载入*/-webkit-transition-delay: 99999s;-webkit-transition: color 99999s ease-out, background-color 99999s ease-out;}
  .focus { transform: scale(0.7);width: 20px;opacity: 1}
  .loadingBox {position: fixed;top: 50%;left: 50%;transform: translate(-50%,-50%)}
  .loginPage {margin-top: 100px;font-size: 16px;color: #333;text-align: center;}
  .loginBtn {margin-left: 10px;font-size: 12px;color: #fff;width: 90px;}
  .noLoginBtn {margin-left: 10px;font-size: 12px;background-color: #ccc;color: #999;border-width: 0;width: 90px;}
  .backgroundBox { position: fixed; top: 0; left: 0; width: 100vw; height: 100vh; background-attachment:fixed; background-image: url(../img/ico_03.jpg); transition:all .5s; background-color:#f8f9fa; filter:"progid:DXImageTransform.Microsoft.AlphaImageLoader(sizingMethod='scale')";  
    -moz-background-size:100% auto;        background-size:100% auto;  };
  .focus { width: 200px; opacity: 1};
  .loadingBox { background-color:rgba(255,255,255,0.97); padding:50px 100px 30px 120px; border-radius:4px; position:fixed; top:40%; left:50%; transform:translate(-50%,-50%); box-shadow:0 0 10px #000;};
  .loadingTitle { position:fixed; top:50%; left:50%; margin-left: -45; margin-top: -18; color:#000; font-weight:500; font-size:24px;}
  .loginPage {position: flex;top: 20%;left: 30%;}

  /* cancelForm */
  .cancelForm {width: 100%;height: 200px;padding: 8px;border: 1px solid #e8e8e8;}
  .cancelForm{  min-width: 64%;  height: 200px;  margin-right: 30px;  padding: 0;  font-variant: tabular-nums;  list-style: none;  font-feature-settings: 'tnum';  position: relative;  display: inline-block;  padding: 4px 11px;  padding-right: 11px;  color: rgba(0, 0, 0, 0.65);  font-size: 14px;  line-height: 1.5;  background-color: #fff;  background-image: none;  border: 1px solid #d9d9d9;  border-top-color: rgb(217, 217, 217);  border-right-color: rgb(217, 217, 217);  border-right-width: 1px;  border-bottom-color: rgb(217, 217, 217);  border-left-color: rgb(217, 217, 217);  border-radius: 2px;  transition: all 0.3s;}
  .cancelForm:hover{border-color: #ff4d4f;border-right-width: 1px !important;}
  .cancelForm::placeholder{color: #bfbfbf;}
  .cancelForm:focus{border-color: #ff4d4f;outline: -webkit-focus-ring-color auto 0;}

  /* express */
  .fahuo{display: flex;flex-direction: column;height: 100px;justify-content: space-around;}
  .fahuo .ipt{margin-right: 30px;padding: 0;font-variant: tabular-nums;list-style: none;font-feature-settings: 'tnum';position: relative;width: 100%;display: inline-block;height: 32px;padding: 4px 11px;padding-right: 11px;color: rgba(0, 0, 0, 0.65);font-size: 14px;line-height: 1.5;background-color: #fff;background-image: none;border: 1px solid #d9d9d9;border-top-color: rgb(217, 217, 217);border-right-color: rgb(217, 217, 217);border-right-width: 1px;border-bottom-color: rgb(217, 217, 217);border-left-color: rgb(217, 217, 217);border-radius: 2px;transition: all 0.3s;}
  .fahuo .ipt:hover {border-color: #ff4d4f;border-right-width: 1px !important;}
  .fahuo .ipt::placeholder{color: #bfbfbf;}
  .fahuo .ipt:focus{border-color: #ff4d4f !important;outline: -webkit-focus-ring-color auto 0;}

  /* list+search+tableblock */
  .between {margin: 16px 0px;display: flex;justify-content: space-between;align-items: center;}
  .list {width:100%}
  .content .body {padding: 24px;background-color: #fff;display: flex;flex-direction: column;justify-content: flex-start;}
  .content .body .bread {padding-bottom: 16px;}
  .content .body .btn {width: max-content;margin-bottom: 20px;}
  .search{text-align: left;}
  .search .date{margin-right: 30px;}
  .search .ipt{margin-bottom: 10px;box-sizing: border-box;margin-right: 30px;padding: 0;font-variant: tabular-nums;list-style: none;font-feature-settings: 'tnum';position: relative;display: inline-block;width: 200px;height: 32px;padding: 4px 11px;color: rgba(0, 0, 0, 0.65);font-size: 14px;line-height: 1.5;background-color: #fff;background-image: none;border: 1px solid #d9d9d9;    border-top-color: rgb(217, 217, 217);    border-right-color: rgb(217, 217, 217);    border-right-width: 1px;    border-bottom-color: rgb(217, 217, 217);    border-left-color: rgb(217, 217, 217);border-radius: 2px;transition: all 0.3s;}
  .search .ipt:hover{border-color: #ff4d4f;border-right-width: 1px !important;}
  .search .ipt:focus{border-color: #ff4d4f;outline: -webkit-focus-ring-color auto 0;}
  .search .ipt::placeholder{color: #bfbfbf;}
  .body .ipt1{width: 50px;text-align: center;box-sizing: border-box;margin: 0;padding: 0;font-variant: tabular-nums;list-style: none;font-feature-settings: 'tnum';position: relative;display: inline-block;height: 32px;padding: 4px 11px;color: rgba(0, 0, 0, 0.65);font-size: 14px;line-height: 1.5;background-color: #fff;background-image: none;border: 1px solid #d9d9d9;    border-top-color: rgb(217, 217, 217);    border-right-color: rgb(217, 217, 217);    border-right-width: 1px;    border-bottom-color: rgb(217, 217, 217);    border-left-color: rgb(217, 217, 217);border-radius: 2px;transition: all 0.3s;}
  .body .ipt1:hover{border-color: #ff4d4f;border-right-width: 1px !important;}
  .body .ipt1:focus{border-color: #ff4d4f;outline: -webkit-focus-ring-color auto 0;}
  .body .ipt1::placeholder{color: #bfbfbf;}
  .body .ipt1::-webkit-inner-spin-button {-webkit-appearance: none;}
  .body .ipt1::-webkit-outer-spin-button {-webkit-appearance: none;}
  .body input[type="number"]{  -moz-appearance: textfield;}
  .body hr{margin: 20px 0;}
  .body .bread .account{padding:30px;background: rgba(245,34,45,0.04);border: 1px solid #f5222d;border-radius: 2px;font-family: Arial, Helvetica, sans-serif;display: flex;align-items: center;}
  .body .bread .tittle{font-size: 20px;font-weight: bold;}
  .body .bread .ico{background-size: cover;width: 32px;height: 32px;margin-right: 10px;}
  .body .bread .number{font-size: 20px;font-weight: bold;}

  /* form */
  .content .header {padding: 12px 24px;background-color: #fff;}
  .content .header .title {margin-top: 8px;font-size: 20px;font-weight: 600;color: rgba(0, 0, 0, 0.85);}
  .content .body {display: flex;justify-content: space-between;margin: 24px 24px 0;padding: 24px;background-color: #fff;}
  .content .body .input_con {width: 450px;}
  .content .body .text_con {width: calc(-362%);}
  /* .content .body .info {padding: 12px;background-color: #e6f7ff;border: 1px solid #91d5ff;} */
  .from{width: 100%;box-sizing: border-box;}
  .from-double{display: flex;flex-wrap: wrap;}
  .from .items{margin-top: 20px;min-width: 50%;max-width: 100%;}
  .from .items-btn{margin-top: 20px;width: 100%;}
  .from .items textarea{min-width: 50%;margin-right: 30px; min-height: 32px;}
  .from .items input{margin-right: 30px;padding: 0;font-variant: tabular-nums;list-style: none;font-feature-settings: 'tnum';display: inline-block;min-width: 50%;height: 32px;padding: 4px 11px;padding-right: 11px;color: rgba(0, 0, 0, 0.65);font-size: 14px;line-height: 1.5;background-color: #fff;background-image: none;border: 1px solid #d9d9d9;border-top-color: rgb(217, 217, 217);border-right-color: rgb(217, 217, 217);border-right-width: 1px;border-bottom-color: rgb(217, 217, 217);border-left-color: rgb(217, 217, 217);border-radius: 2px;transition: all 0.3s;}
  .from .items input:hover {border-color: #ff4d4f;border-right-width: 1px !important;}
  .from .items input:focus{border-color: #ff4d4f;outline: -webkit-focus-ring-color auto 0;}
  .from .items input::placeholder{color: #bfbfbf;}
  .from .items select{margin-right: 30px;padding: 0;font-variant: tabular-nums;list-style: none;font-feature-settings: 'tnum';display: inline-block;min-width: 50%;height: 32px;padding: 4px 11px;padding-right: 11px;color: rgba(0, 0, 0, 0.65);font-size: 14px;line-height: 1.5;background-color: #fff;background-image: none;border: 1px solid #d9d9d9;border-top-color: rgb(217, 217, 217);border-right-color: rgb(217, 217, 217);border-right-width: 1px;border-bottom-color: rgb(217, 217, 217);border-left-color: rgb(217, 217, 217);border-radius: 2px;transition: all 0.3s;}
  .from .items select:hover {border-color: #ff4d4f;border-right-width: 1px !important;}
  .from .items select:focus{border-color: #ff4d4f;outline: -webkit-focus-ring-color auto 0;}
  .from .items select::placeholder{color: #bfbfbf;}
  .from .items .btn{color: #fff;background-color: #ff0000;line-height: 1.499;text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);position: relative;display: inline-block;font-weight: 400;white-space: nowrap;text-align: center;background-image: none;border: 1px solid transparent;border-top-color: transparent;border-right-color: transparent;border-bottom-color: transparent;border-left-color: transparent;box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);cursor: pointer;transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);user-select: none;touch-action: manipulation;height: 32px;padding: 0 15px;font-size: 14px;border-radius: 2px;}
  .from .items .btn:hover{color: #fff;  background-color: #ff4d4f;  border-color: #ff4d4f;}
  .from .items .btn:focus{border-color: #ff4d4f;outline: -webkit-focus-ring-color auto 0;}
  .from div label{height: 32px;line-height: 32px;display: inline-block;}
  .from div label input{width: 16px;height: 16px;}
  .from table{width: 100%;border: 1px solid #d9d9d9;margin-top: 30px;}
  .from table .th{white-space: nowrap;;width:max-content;border: 1px solid #d9d9d9;color: rgba(0, 0, 0, 0.85);padding: 16px;font-weight: 500;text-align: left;background: #fafafa;border-bottom: 1px solid #e8e8e8;transition: background 0.3s ease;}
  .from table .td{max-width: 200px;border: 1px solid #d9d9d9;padding:16px;word-break: break-all;word-wrap: break-word;}
  .from .items{display: flex;align-items: center;}
  .from .items .tittle{width: 100px;}
  .from .items .tittle .place span{width: 100%;}
  .from .img{width:max-content;display: flex;align-items: center;margin-top: 20px;min-width: 50%;max-width: 100%;}
  .from .img span{display: flex;align-items: center;width: max-content;}
  .from .img .kong{width: 100px;}
  .from div .ipt{margin-right: 30px; padding: 0; font-variant: tabular-nums; list-style: none;font-feature-settings: 'tnum'; position: relative; display: inline-block; min-width: 300px; height: 32px; padding: 4px 11px; padding-right: 11px; color: rgba(0, 0, 0, 0.65);font-size: 14px;line-height: 1.5;background-color: #fff;background-image: none;border: 1px solid #d9d9d9; border-top-color: rgb(217, 217, 217); border-right-color: rgb(217, 217, 217); border-right-width: 1px;border-bottom-color: rgb(217, 217, 217);border-left-color: rgb(217, 217, 217);border-radius: 2px;transition: all 0.3s;}
  .from div .ipt:hover {border-color: #ff4d4f;border-right-width: 1px !important;}
  .from .btn{line-height: 1.499;margin-right: 55%;color: #fff;margin-top: 20px; background-color: #F5222D;border-color: #F5222D; text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12); box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);position: relative;display: inline-block; font-weight: 400; white-space: nowrap;text-align: center; background-image: none; border: 1px solid transparent; border-top-color: transparent; border-right-color: transparent; border-bottom-color: transparent; border-left-color: transparent; box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015); cursor: pointer;transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); user-select: none; touch-action: manipulation; height: 32px; padding: 0 15px; font-size: 14px;border-radius: 2px;}
  .from .btn:hover{color: #fff; background-color: #ff4d4f; border-color: #ff4d4f;}
  .from .btn:focus{border-color: #ff4d4f !important;outline: -webkit-focus-ring-color auto 0;}
  .from table{width: 100%;border: 1px solid #d9d9d9;margin-top: 30px;}
  .from table .th{border: 1px solid #d9d9d9;color: rgba(0, 0, 0, 0.85);padding: 16px; font-weight: 500; text-align: left; background: #fafafa; border-bottom: 1px solid #e8e8e8; transition: background 0.3s ease; min-width: 80px; word-break: break-all; white-space: nowrap;}
  .from table .td{border: 1px solid #d9d9d9;color: rgba(0, 0, 0, 0.85);padding: 16px; font-weight: 500; text-align: left; border-bottom: 1px solid #e8e8e8; transition: background 0.3s ease; min-width: 10% !important; max-width: 20%; text-align: justify; word-break: break-all;}
  .from table td{border: 1px solid #d9d9d9;padding:16px;max-width: 10%;}
  .from .select{display: flex;align-items: center;margin-top: 20px;}
  .from .select .tittle{width: 100px;}
  .from .select label{display: flex;align-items: center;}
  .from .select div{display: flex;align-items: center;}
  .from .select .kong{width: 100px;text-align: justify !important;}
  .from .select input{display: none !important;}
  .from hr{margin-top: 20px;}
  .content .body .titles .title{font-size: 20px;font-weight: bold;color: #333;}


  .pop{height: 500px;overflow-y: auto;}
  .pop .items{margin-top: 20px;display: flex;align-items: center;}
  .pop::-webkit-scrollbar{/*滚动条整体样式*/ width: 4px;     /*高宽分别对应横竖滚动条的尺寸*/ height: 1px;}
  .pop::-webkit-scrollbar-thumb {/*滚动条里面小方块*/ border-radius: 10px; -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.1); background: #bfbfbf;}
  .pop::-webkit-scrollbar-track {/*滚动条里面轨道*/ -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.1); border-radius: 10px; background: #EDEDED;}
  .pop div .ipt{ margin-right: 30px; padding: 0; font-variant: tabular-nums; list-style: none; font-feature-settings: 'tnum'; position: relative; display: inline-block; min-width: 300px; height: 32px; padding: 4px 11px; padding-right: 11px; color: rgba(0, 0, 0, 0.65); font-size: 14px; line-height: 1.5; background-color: #fff; background-image: none; border: 1px solid #d9d9d9; border-top-color: rgb(217, 217, 217); border-right-color: rgb(217, 217, 217); border-right-width: 1px; border-bottom-color: rgb(217, 217, 217); border-left-color: rgb(217, 217, 217); border-radius: 2px; transition: all 0.3s;}
  .pop div .ipt:hover {border-color: #ff4d4f;border-right-width: 1px !important;}
  .pop .select{display: flex;align-items: center;margin-top: 20px;}
  .pop .select label{display: flex;align-items: center;}
  .pop .select div{display: flex;align-items: center;}
  .pop .select .kong{width: 100px;text-align: justify !important;}
  .pop .select input{display: none !important;}
  .pop .img{display: flex;align-items: center;}
  .pop .img .kong{width: 100px;}
  .pop .img span{width: max-content;display: flex;align-items: center;}
  .pop input:focus{border-color: #ff4d4f !important;outline: -webkit-focus-ring-color auto 0;}
  .content .editor{display:inline-block;width: 800px;}
  .from .editor{ min-width: 64%; /* margin-right: 30px; */ padding: 0; font-variant: tabular-nums; list-style: none; font-feature-settings: 'tnum'; position: relative; display: inline-block; padding: 4px 11px; padding-right: 11px; color: rgba(0, 0, 0, 0.65); font-size: 14px; line-height: 1.5; background-color: #fff; background-image: none; border: 1px solid #d9d9d9; border-top-color: rgb(217, 217, 217); border-right-color: rgb(217, 217, 217); border-right-width: 1px; border-bottom-color: rgb(217, 217, 217); border-left-color: rgb(217, 217, 217); border-radius: 2px; transition: all 0.3s;}
  .from .editor .dropdown-handler{color: #333;background-color: #fff;}
  .shangchuan{width: max-content !important;}
  .shangchuan div{margin: 0 !important;padding: 0 !important;border: none !important;width: max-content !important;height: max-content !important;}
  .shangchuan span{margin: 0 !important;padding: 0 !important;background:#fff !important;width: 100px !important;}
  .shangchuan div span{width: max-content !important;}
  .shangchuan .tupian{ margin: 0; padding: 0;}
  .from .select{display: flex;align-items: center;margin-top: 20px;}
  .from .select label{display: flex;align-items: center;}
  .from .select div{display: flex;align-items: center;}
  .from .select .kong{width: 100px;text-align: justify !important;}
  .from .select input{display: none !important;}
  .items .shuru{white-space: nowrap;box-sizing: border-box;width: 300px;}
  .items .kong{width: 100px;text-align: justify !important;}
  .items .txt{min-width: 64%;height: 200px;margin-right: 30px;padding: 0;font-variant: tabular-nums;list-style: none;font-feature-settings: 'tnum';position: relative;display: inline-block;padding: 4px 11px;padding-right: 11px;color: rgba(0, 0, 0, 0.65);font-size: 14px;line-height: 1.5; background-color: #fff;background-image: none;border: 1px solid #d9d9d9;border-top-color: rgb(217, 217, 217);border-right-color: rgb(217, 217, 217);border-right-width: 1px;border-bottom-color: rgb(217, 217, 217);border-left-color: rgb(217, 217, 217); border-radius: 2px;transition: all 0.3s;}
  .items .txt:hover{border-color: #ff4d4f;border-right-width: 1px !important;}
  .items .txt::placeholder{color: #bfbfbf;}
  .items .txt:focus{border-color: #ff4d4f;outline: -webkit-focus-ring-color auto 0;}


  /* 发布产品publishproduct */
  .publishproduct .content .header {padding: 12px 24px;background-color: #fff;}
  .publishproduct .content .header .title {margin-top: 8px;font-size: 20px;font-weight: 600;color: rgba(0, 0, 0, 0.85);}
  .publishproduct .content .body {display: flex;justify-content: space-between;margin: 24px 24px 0;padding: 24px;background-color: #fff;}
  .publishproduct .content .body .input_con {width: 450px;}
  .publishproduct .content .body .text_con {width: calc(-362%);}
  .publishproduct .content .body .info {padding: 12px;background-color: #e6f7ff;border: 1px solid #91d5ff;}
  .publishproduct .from{width: 100%;box-sizing: border-box;}
  .publishproduct .items{margin-top: 20px;display: flex;align-items: center;}
  .publishproduct .items input::placeholder{color: #bfbfbf;}
  .publishproduct .from div input:focus{border-color: #ff4d4f !important;outline: -webkit-focus-ring-color auto 0;}
  .publishproduct .items .shuru{white-space: nowrap;box-sizing: border-box;width: 300px;}
  .publishproduct .items .kong{width: 100px;text-align: justify !important;}
  .publishproduct .items .txt{min-width: 64%;height: 200px;margin-right: 30px;padding: 0;font-variant: tabular-nums;list-style: none;font-feature-settings: 'tnum';position: relative;display: inline-block;padding: 4px 11px;padding-right: 11px;color: rgba(0, 0, 0, 0.65);font-size: 14px;line-height: 1.5; background-color: #fff;background-image: none;border: 1px solid #d9d9d9;border-top-color: rgb(217, 217, 217);border-right-color: rgb(217, 217, 217);border-right-width: 1px;border-bottom-color: rgb(217, 217, 217);border-left-color: rgb(217, 217, 217); border-radius: 2px;transition: all 0.3s;}
  .publishproduct .items .txt:hover{border-color: #ff4d4f;border-right-width: 1px !important;}
  .publishproduct .items .txt::placeholder{color: #bfbfbf;}
  .publishproduct .items .txt:focus{border-color: #ff4d4f;outline: -webkit-focus-ring-color auto 0;}
  .publishproduct .from .img{width:max-content;display: flex;align-items: center;margin-top: 20px;}
  .publishproduct .from .img span{width: max-content;display: flex;align-items: center;}
  .publishproduct .from .img .kong{width: 100px;}
  .publishproduct .from div .ipt{margin-right: 30px; padding: 0; font-variant: tabular-nums; list-style: none;font-feature-settings: 'tnum'; position: relative; display: inline-block; min-width: 300px; height: 32px; padding: 4px 11px; padding-right: 11px; color: rgba(0, 0, 0, 0.65);font-size: 14px;line-height: 1.5;background-color: #fff;background-image: none;border: 1px solid #d9d9d9; border-top-color: rgb(217, 217, 217); border-right-color: rgb(217, 217, 217); border-right-width: 1px;border-bottom-color: rgb(217, 217, 217);border-left-color: rgb(217, 217, 217);border-radius: 2px;transition: all 0.3s;}
  .publishproduct .from div .ipt:hover {border-color: #ff4d4f;border-right-width: 1px !important;}
  .publishproduct .from .btn{line-height: 1.499;margin-right: 30px;color: #fff;margin-top: 20px; background-color: #F5222D;border-color: #F5222D; text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12); box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);position: relative;display: inline-block; font-weight: 400; white-space: nowrap;text-align: center; background-image: none; border: 1px solid transparent; border-top-color: transparent; border-right-color: transparent; border-bottom-color: transparent; border-left-color: transparent; box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015); cursor: pointer;transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); user-select: none; touch-action: manipulation; height: 32px; padding: 0 15px; font-size: 14px;border-radius: 2px;}
  .publishproduct .from .btn:hover{color: #fff; background-color: #ff4d4f; border-color: #ff4d4f;}
  .publishproduct .from .btn:focus{border-color: #ff4d4f !important;outline: -webkit-focus-ring-color auto 0;}
  .publishproduct .from table{width: 100%;border: 1px solid #d9d9d9;margin-top: 30px;}
  .publishproduct .from table .th{border: 1px solid #d9d9d9;color: rgba(0, 0, 0, 0.85);padding: 16px; font-weight: 500; text-align: left; background: #fafafa; border-bottom: 1px solid #e8e8e8; transition: background 0.3s ease; min-width: 80px; word-break: break-all; white-space: nowrap;}
  .publishproduct .from table .td{border: 1px solid #d9d9d9;color: rgba(0, 0, 0, 0.85);padding: 16px; font-weight: 500; text-align: left; border-bottom: 1px solid #e8e8e8; transition: background 0.3s ease; min-width: 10% !important; max-width: 20%; text-align: justify; word-break: break-all;}
  .publishproduct .from table td{border: 1px solid #d9d9d9;padding:16px;max-width: 10%;}
  .publishproduct .from .select{display: flex;align-items: center;margin-top: 20px;}
  .publishproduct .from .select label{display: flex;align-items: center;}
  .publishproduct .from .select div{display: flex;align-items: center;}
  .publishproduct .from .select .kong{width: 100px;text-align: justify !important;}
  .publishproduct .from .select input{display: none !important;}
  .publishproduct .from hr{margin-top: 20px;}
  .publishproduct .pop{height: 500px;overflow-y: auto;}
  .publishproduct .pop::-webkit-scrollbar{/*滚动条整体样式*/ width: 4px;     /*高宽分别对应横竖滚动条的尺寸*/ height: 1px;}
  .publishproduct .pop::-webkit-scrollbar-thumb {/*滚动条里面小方块*/ border-radius: 10px; -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.1); background: #bfbfbf;}
  .publishproduct .pop::-webkit-scrollbar-track {/*滚动条里面轨道*/ -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.1); border-radius: 10px; background: #EDEDED;}
  .publishproduct .pop div .ipt{ margin-right: 30px; padding: 0; font-variant: tabular-nums; list-style: none; font-feature-settings: 'tnum'; position: relative; display: inline-block; min-width: 300px; height: 32px; padding: 4px 11px; padding-right: 11px; color: rgba(0, 0, 0, 0.65); font-size: 14px; line-height: 1.5; background-color: #fff; background-image: none; border: 1px solid #d9d9d9; border-top-color: rgb(217, 217, 217); border-right-color: rgb(217, 217, 217); border-right-width: 1px; border-bottom-color: rgb(217, 217, 217); border-left-color: rgb(217, 217, 217); border-radius: 2px; transition: all 0.3s;}
  .publishproduct .pop div .ipt:hover {border-color: #ff4d4f;border-right-width: 1px !important;}
  .publishproduct .pop .select{display: flex;align-items: center;margin-top: 20px;}
  .publishproduct .pop .select label{display: flex;align-items: center;}
  .publishproduct .pop .select div{display: flex;align-items: center;}
  .publishproduct .pop .select .kong{width: 100px;text-align: justify !important;}
  .publishproduct .pop .select input{display: none !important;}
  .publishproduct .pop .img{display: flex;align-items: center;}
  .publishproduct .pop .img .kong{width: 100px;}
  .publishproduct .pop .img span{width: max-content;display: flex;align-items: center;}
  .publishproduct .pop input:focus{border-color: #ff4d4f !important;outline: -webkit-focus-ring-color auto 0;}
  .publishproduct .content .editor{display:inline-block;width: 800px;}
  .publishproduct .from .editor{ min-width: 64%; /* margin-right: 30px; */ padding: 0; font-variant: tabular-nums; list-style: none; font-feature-settings: 'tnum'; position: relative; display: inline-block; padding: 4px 11px; padding-right: 11px; color: rgba(0, 0, 0, 0.65); font-size: 14px; line-height: 1.5; background-color: #fff; background-image: none; border: 1px solid #d9d9d9; border-top-color: rgb(217, 217, 217); border-right-color: rgb(217, 217, 217); border-right-width: 1px; border-bottom-color: rgb(217, 217, 217); border-left-color: rgb(217, 217, 217); border-radius: 2px; transition: all 0.3s;}
  .publishproduct .shangchuan{width: max-content !important;}
  .publishproduct .shangchuan div{margin: 0 !important;padding: 0 !important;border: none !important;width: max-content !important;height: max-content !important;}
  .publishproduct .shangchuan span{margin: 0 !important;padding: 0 !important;background:#fff !important;width: 100px !important;}
  .publishproduct .shangchuan div span{width: max-content !important;}
  .publishproduct .shangchuan .tupian{ margin: 0; padding: 0;}

  /*首页样式*/
  .test{width: 100%;text-align: center;min-height: 80vh;margin: 20px;display: flex;flex-direction: column;align-items: center;justify-content: space-evenly;}
  .test h1{font-size: 40px;font-weight: bold;}
  .test h1 span{font-size: 16px;font-weight: normal;}
  .test .icon{width: 100%;}
  .test .foot{color: #ccc;}

  /*快递详情*/
  .bodyExpressInfo{margin: 24px 24px 0;padding: 48px;background-color: #fff;}
  .bodyExpressInfo .status{margin-bottom: 20px;background: #fff5f5;border: 1px solid #ff4d4f;padding: 16px 16px 20px 16px;}
  .bodyExpressInfo .details{border: 1px solid #ebebeb;padding: 10px 20px;margin-top: 46px;position: relative;}
  .bodyExpressInfo .details .expressTitle{margin-top: -24px;border: 1px solid #ebebeb;border-bottom: none;position: absolute;top: -7px;left: 20px;line-height: 22px;padding: 0 14px;line-height: 30px;background: #fff;}
  .bodyExpressInfo .details .expressMode{padding: 12px 0 0;border-bottom: 1px solid #ebebeb;}
  .bodyExpressInfo .details .expressMode .mc{display: flex;flex-wrap: wrap;margin-top: 30px;}
  .bodyExpressInfo .details .expressMode .mc .item{width: 33%;display: flex;padding-right: 20px;word-break: break-all;}
  .bodyExpressInfo .details .expressMode .mc .item .title{width: 85px;display: inline-block;text-align: justify;}
  .bodyExpressInfo .details .expressMode .mc .item .title::after{width: 85px;display: inline-block;text-align: justify;content: "";}
  .bodyExpressInfo .details .expressMode .expressinfo{width: 100%;border-collapse: separate;border: 1px solid #f2f2f2;padding: 0;margin: 0;font-size: 12px;margin-bottom: 15px;}
  .bodyExpressInfo .details .expressMode .expressinfo .th{background: #f2f2f2;height: 26px;}
  .bodyExpressInfo .details .expressMode .expressinfo .th th{height: 26px;padding: 12px 8px;white-space: nowrap;}
  .bodyExpressInfo .details .expressMode .expressinfo .td{height: 26px;}
  .bodyExpressInfo .details .expressMode .expressinfo .td td{padding: 12px 8px;}
  .bodyExpressInfo .details .expressModeWuliu{padding: 12px 0 0;}
  .bodyExpressInfo .details .expressModeWuliu .mc{flex-wrap: wrap;}
  .bodyExpressInfo .details .expressModeWuliu .mc .item{margin:10px 0 10px;}
  .bodyExpressInfo .details .expressModeWuliu .mc .item .time{display: inline-block;margin:0 20px;}
  .bodyExpressInfo .details .expressModeWuliu .mc .item .time::before{width: 8px;height: 8px;content: "";background-color: #ccc;position: absolute;border-radius: 6px;left: 20px;margin-top: 7px;}
 

  /*添加学校*/
  .schoolTitle{padding: 12px 24px;background-color: #fff;}
  .schoolTitle .title{margin-top: 8px;font-size: 20px;font-weight: 600;color: rgba(0, 0, 0, 0.85);}
  .bodySchool{margin: 24px 24px 0;padding: 48px;background-color: #fff;}
  .bodySchool .title{background-color: #fff;margin-bottom: 20px;font-size: 20px;font-weight: bold;color: #333;}
  
  .bodySchool .addSchool .title{font-size: 18px;font-weight: bold;}
  .bodySchool .addSchool .details{/*border: 1px solid #ebebeb;padding: 20px 0;margin-top: 20px;*/position: relative;}
  .bodySchool .addSchool .details .items{margin-top: 20px;display: flex;align-items: center;}
  .bodySchool .addSchool .details .items .name{min-width: 100px;display: inline-block;}
  .ant-cascader-picker{min-width: 20%;}
  .bodySchool .addSchool .details .items .content{padding:0 10px;min-width: 20%;border: 1px solid #d9d9d9;border-radius: 2px;height: 32px;outline: none;}
  .bodySchool .addSchool .details .items .content:hover{border-color: #ff4d4f;}
  .bodySchool .addSchool .details .items .content::placeholder{color: #ccc;}
  .bodySchool .addSchool .btns{display: flex;margin-top: 20px;margin-left: 100px;}
  .bodySchool .addSchool .btns button{margin-right: 10px;border: 1px solid #F5222D;padding: 4px 15px;font-size: 14px;border-radius: 2px;-webkit-appearance: none;outline: none;transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);}
  .bodySchool .addSchool .btns .btn{background-color: #F5222D;color: #fff;}
  .bodySchool .addSchool .btns .btn:hover{background-color: #ff4d4f;}
  .bodySchool .addSchool .btns .del{background-color: #fff;color:#F5222D ;}
  .bodySchool .addSchool .btns .del:hover{border: 1px solid #ff4d4f;color:#F5222D;background-color: rgba(0,0,0,0.01);}

  /* .addTake{margin-top: 20px;border-top: 1px solid #ebebeb;} */
  .addTake .title{font-size: 18px;font-weight: bold;}
  .addTake .details{/*border: 1px solid #ebebeb;padding: 20px 0;margin-top: 20px;*/position: relative;}
  .addTake .details .items{margin-top: 20px;}
  .addTake .details .items .name{min-width: 100px;display: inline-block;}
  .addTake .details .items .content{padding:0 10px;min-width: 300px;border: 1px solid #d9d9d9;border-radius: 2px;height: 32px;outline: none;}
  .addTake .details .items .content:hover{border-color: #ff4d4f;}
  .addTake .details .items .content::placeholder{color: #ccc;}
  .addTake .btns{display: flex;margin-top: 20px;}
  .addTake .btns button{margin-right: 10px;border: 1px solid #F5222D;padding: 4px 15px;font-size: 14px;border-radius: 2px;-webkit-appearance: none;outline: none;transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);}
  .addTake .btns .btn{background-color: #F5222D;color: #fff;}
  .addTake .btns .btn:hover{background-color: #ff4d4f;}
  .addTake .btns .del{background-color: #fff;color:#F5222D ;}
  .addTake .btns .del:hover{border: 1px solid #ff4d4f;color:#ff4d4f;background-color: rgba(0,0,0,0.01);}

  .addGet .title{font-size: 18px;font-weight: bold;}
  .addGet .details{/*border: 1px solid #ebebeb;padding: 20px 0;margin-top: 20px;*/position: relative;}
  .addGet .details .items{margin-top: 20px;}
  .addGet .details .items .name{min-width: 100px;display: inline-block;}
  .addGet .details .items .content{padding:0 10px;min-width: 300px;border: 1px solid #d9d9d9;border-radius: 2px;height: 32px;outline: none;}
  .addGet .details .items .content:hover{border-color: #ff4d4f;}
  .addGet .details .items .content::placeholder{color: #ccc;}
  .addGet .btns{display: flex;margin-top: 20px;}
  .addGet .btns button{margin-right: 10px;border: 1px solid #F5222D;padding: 4px 15px;font-size: 14px;border-radius: 2px;-webkit-appearance: none;outline: none;transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);}
  .addGet .btns .btn{background-color: #F5222D;color: #fff;}
  .addGet .btns .btn:hover{background-color: #ff4d4f;}
  .addGet .btns .del{background-color: #fff;color:#F5222D ;}
  .addGet .btns .del:hover{border: 1px solid #ff4d4f;color:#ff4d4f;background-color: rgba(0,0,0,0.01);}

  /*学校详情*/
  .schoolTitle{padding: 12px 24px;background-color: #fff;}
  .schoolTitle .title{margin-top: 8px;font-size: 20px;font-weight: 600;color: rgba(0, 0, 0, 0.85);}
  .bodySchoolInfo{margin: 24px 24px 0;padding: 48px;background-color: #fff;}
  .bodySchoolInfo .title{background-color: #fff;margin-bottom: 20px;}
  
  .bodySchoolInfo .addSchool .title{font-size: 18px;font-weight: bold;}
  .bodySchoolInfo .addSchool .details{/*border: 1px solid #ebebeb;padding: 20px 0;margin-top: 20px;*/position: relative;}
  .bodySchoolInfo .addSchool .details .items{margin-top: 20px;}
  .bodySchoolInfo .addSchool .details .items .name{min-width: 100px;display: inline-block;}
  .bodySchoolInfo .addSchool .details .items .content{padding:0 10px;min-width: 300px;border-radius: 2px;height: 32px;outline: none;}
  .bodySchoolInfo .addSchool .details .items .content:hover{border-color: #ff4d4f;}
  .bodySchoolInfo .addSchool .details .items .content::placeholder{color: #ccc;}
  .bodySchoolInfo .addSchool .btns{display: flex;margin-top: 20px;}
  .bodySchoolInfo .addSchool .btns button{margin-right: 10px;border: 1px solid #F5222D;padding: 4px 15px;font-size: 14px;border-radius: 2px;-webkit-appearance: none;outline: none;transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);}
  .bodySchoolInfo .addSchool .btns .btn{background-color: #F5222D;color: #fff;}
  .bodySchoolInfo .addSchool .btns .btn:hover{background-color: #ff4d4f;}
  .bodySchoolInfo .addSchool .btns .del{background-color: #fff;color:#F5222D ;}
  .bodySchoolInfo .addSchool .btns .del:hover{border: 1px solid #ff4d4f;color:#F5222D;background-color: rgba(0,0,0,0.01);}

  .bodySchoolInfo .addTake .title{font-size: 18px;font-weight: bold;margin-top: 20px;}
  .bodySchoolInfo .addTake .takeInfo{width: 100%;border-collapse: separate;border: 1px solid #f2f2f2;padding: 0;margin: 0;font-size: 12px;margin-bottom: 15px;border-spacing: 0;border-collapse: collapse;}
  .bodySchoolInfo .addTake .takeInfo .th{background-color: #f2f2f2;height: 26px;}
  .bodySchoolInfo .addTake .takeInfo .th th{height: 26px;padding: 12px 8px;border: 1px solid #e8e8e8;}
  .bodySchoolInfo .addTake .takeInfo .td{height: 26px;}
  .bodySchoolInfo .addTake .takeInfo .td td{padding: 12px 8px;border: 1px solid #f2f2f2;}
  .bodySchoolInfo .addTake .takeInfo .td td .edit{color: #ff0000;}

  .bodySchoolInfo .addGet .title{font-size: 18px;font-weight: bold;margin-top: 20px;}
  .bodySchoolInfo .addGet .getInfo{width: 100%;border-collapse: separate;border: 1px solid #f2f2f2;padding: 0;margin: 0;font-size: 12px;margin-bottom: 15px;border-spacing: 0;border-collapse: collapse;}
  .bodySchoolInfo .addGet .getInfo .th{background-color: #f2f2f2;height: 26px;}
  .bodySchoolInfo .addGet .getInfo .th th{height: 26px;padding: 12px 8px;border: 1px solid #e8e8e8;}
  .bodySchoolInfo .addGet .getInfo .td{height: 26px;}
  .bodySchoolInfo .addGet .getInfo .td td{padding: 12px 8px;border: 1px solid #f2f2f2;}
  .bodySchoolInfo .addGet .getInfo .td td .edit{color: #ff0000;}

  /*添加快递员*/
  .from .items .address{width: 50%;margin: 0;}
  .from .items .address .th{color: rgba(0, 0, 0, 0.85);font-weight: 500;text-align: left;background: #fafafa;position: relative;padding: 16px 16px;overflow-wrap: break-word;}
  .from .items .address .th th{padding: 16px 16px;overflow-wrap: break-word;border: 1px solid #f0f0f0;}
  .from .items .address .td td{padding: 8px 16px;overflow-wrap: break-word;border: 1px solid #f0f0f0;}
  .from .items .address .td input{padding: 0;margin: 0;display: block;width: 16px;}
  
  /*分配快递员*/
  .fahuo .item{margin-bottom: 5px;}
  .fahuo .item .title{width: 90px;display: inline-block;}
  /* .fahuo .select{display: flex;} */
  .fahuo .select .ant-select{width: 80%;display: inline-block;}
  .fahuo .select .title{width: 90px;display: inline-block;}

  /*面包屑*/
  .mbx{padding:0 0 0 20px !important;margin-top: 20px !important;}
  
  /* 发布淘礼金 */
  .jieXiWenAn{display: inline-block;  background: #fafafa; box-sizing: border-box; padding: 10px;}
  .jieXiWenAnInfo{display: inline-block;  background: #fafafa; box-sizing: border-box; padding: 10px;width:500px}
  .waresnews_l { float: left; width: 100px; height: 100px; }
  .waresnews_l img {width: 100px;height: 100px; }
  .waresnews_r { float: left; width: 311px; margin-left: 15px; }
  .waresnews_r_top {width: 100%;  font-size: 14px;  color: #333;  overflow: hidden;  white-space: nowrap;  text-overflow: ellipsis;  -o-text-overflow: ellipsis;}
  .waresnews_r_top a {color: #333333;}
  .waresnews_r_bottom {margin: 0;  padding: 0;  background: #fff;  width: 100%;  margin-top: 12px;  height: 54px;  display: flex;  align-items: center;}
  .waresnews_r_bottom li {display: block;  float: left;  width: 25%;  text-align: center;  color: #666;}
  .waresnews_r_bottom li p {margin: 0;  padding: 0;  font-size: 12px;}
  .waresnews_r_bottom li p:last-child {color: #5794FC;  font-size: 18px;}
  .waresnews_r_bottomInfo {margin: 0;  padding: 0;  background: #fff;  width: 110%;  margin-top: 12px;  height: 54px;  display: flex;  align-items: center;}
  .waresnews_r_bottomInfo li {display: block;  float: left;  width: 25%;  text-align: center;  color: #666;}
  .waresnews_r_bottomInfo li p {margin: 0;  padding: 0;  font-size: 12px;}
  .waresnews_r_bottomInfo li p:last-child {color: #5794FC;  font-size: 18px;}
  .goods_itemshorttitle{ width: 100%; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
  .items_timer{ display: flex; align-items: center;margin-top: 20px; min-width: 50%;max-width: 100%;}
  .from .items_timer .title { width: 100px; }
  .items_timer .timer {min-width: 50%;}

  /* 淘礼金列表 */
  .tljShortUrl{width: 150px;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
  .tljTpwd{width: 150px;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
  .tljCopyBtn{width: 20px;height: 20px;}
  .tuiGuangDiZhiItem{display: inline-flex;}

  /** 平台参数设置 */
  .from .ht{height:40px;line-height:40px;/* background:#f0f0f0; */margin:40px 0 0;border-bottom:1px solid #f0f0f0;width: 100%;}
  .from .ht:first-child{margin:0;}
  .from .ht h3{display: inline-block;line-height:40px;font-size:16px;color:#333;font-weight: bold;}
  .from .items .settingTittle{width: 200px;}

  .uploadText{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .feedbackContent{
    word-wrap: 'break-word';
    word-break: 'break-word';
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }

  .posterBg{
    /* background-color: #BAD4CB; */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .posterBgImage{
    width: '100%';
    height: 125px;
  }
  .posterRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left:30px;
    padding-right: 30px;
    margin: 10px 0;
    color: #fff;    
    font-size: 25px;
  }
  .posterRow1{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-bottom: 20px;
  }
  .posterTipText1{
    font-size: 16px;
  }
  .posterItem{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }
  .posterImage{
    width: 140px;
    height: 140px;
    border-radius: 10px;
    object-fit:cover;
  }
  .posterTags{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .posterTag{
    background-color: #35C23F;
    color:#fff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-right: 10px;
    padding:1px 15px 1px 10px;
    font-size: 12px;
  }
  .row{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .rowPrice{
    color: #f5222d;
    margin-right: 20px;
    font-size: 20px;
  }
  .rowPriceB{
    color: #999;
    text-decoration: line-through;
  }
  .posterLocation{
    width: 20px;
    height: 20px;
  }
  .posterBtn{
    text-align: center;
    z-index: 999;
    margin-top: 20px;
  }
  .posterCancel{
    padding: 2px 20px 2px 20px;
    margin-right: 30px;
  }
  .posterSure{
    padding: 2px 20px 2px 20px;
    color: #fff;
    background-color: #ff3029;
    border-color: #ff3029;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }
  .posterList{
    background-color: #fff;
    /* border:1px solid #000; */
    padding:15px;
    margin: 20px;
    border-radius: 10px;
  }
  .posterInfo{
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 140px;
  }
  .posterQr{
    width: 120px;
    height: 120px;
    border-radius: 10px;
  }
  .posterTitle{
    font-size: 16px;
    font-weight: bold;
  }
  .ant-modal{
    padding-bottom: 0 !important;
  }
  .posterImageDiv{
    width: 140px;
    height: 140px;
  }
  .imagePicker{width: 104px;height: 104px; border:1px solid #ebebeb;margin: 0 8px 8px 0;}
.imagePicker .image{width: 100%;height: 100%;}
.uploadText{position: relative;margin-bottom: 1rem;}
.simpleRow{display: flex;flex-direction: row;align-items: center;}
.simpleRow ul{display: flex;flex-direction: row;align-items: center;padding-left: 0;}
/* .imagePicker{position: absolute;top: 0;} */
.ant-table{font-size: 12px!important;}


.biTian::before{
  content: "*";
  color: red;
  font-size: 20px;
  position: absolute;
  left: -15px;
}
.biTian{
  position: relative;
}
